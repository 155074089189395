.AppBackground{
    background: rgb(2,0,36);
    background: -moz-linear-gradient(180deg, rgba(2,0,36,1) 28%, rgba(0,212,255,1) 72%);
    background: -webkit-linear-gradient(180deg, rgba(2,0,36,1) 28%, rgba(0,212,255,1) 72%);
    background: linear-gradient(180deg, rgba(2,0,36,1) 28%, rgba(0,212,255,1) 72%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#020024",endColorstr="#00d4ff",GradientType=1);
}d
.AppBackgroundLoading{
    background: white !important;
}
.App{
    background-color: transparent;
}
.fullScreenHeight{
    height: 80vh
}

.mainContentRow{
    height: 30%;
}
.white{
    color: white;
}
.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
 }
@-webkit-keyframes fadeInSignup {
    0% {opacity: 0;}
    100% {opacity: 1;}
}
 
 @keyframes fadeInSignup {
    0% {opacity: 0;}
    100% {opacity: 1;}
 }
 .fadeInSignup {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
 }
 .signup{
    position: sticky;
    position: -webkit-sticky;  
    top: 0px;
}
.signupContainer{
    background: rgb(2,0,36);
background: -moz-linear-gradient(142deg, rgba(2,0,36,1) 28%, rgba(0,212,255,1) 72%);
background: -webkit-linear-gradient(142deg, rgba(2,0,36,1) 28%, rgba(0,212,255,1) 72%);
background: linear-gradient(142deg, rgba(2,0,36,1) 28%, rgba(0,212,255,1) 72%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#020024",endColorstr="#00d4ff",GradientType=1);
  border-radius: 20px;

    z-index: 100;
    padding-bottom: 1rem;
    padding-top: 1rem;
    color: white;
}

.signupRowTop{
    position: fixed;
    top: 0px;
}
.signupRowBottom{
    position: fixed;
    top: 90vh;
}